import { graphql, useStaticQuery } from 'gatsby';

export const useLandingPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      landingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          buildingInTrayImage {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
          sittingManImage {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
        }
        solutionHeadline
      }
    }
  `);

  return data.landingPageDataJson;
};
