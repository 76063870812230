import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { CircleSwirl } from 'components';

export const SolutionHead = ({ staticData, data: { caption } }) => {
  return (
    <Section id="landing-page__solution-head">
      <Headline headline={staticData} />
      <CaptionWithDecoration caption={caption} />
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{ __html: headline }}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 21',
          null,
          '3/ span 15',
          '1/ span 21'
        ],
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        fontSize: ['3.9rem', null, '5.6rem', null, '5.6rem', '7.2rem'],
        '& > span:not([class])': {
          fontFamily: 'primary.bold'
        },

        '& > br[class^="not-phone-portrait"], & > span[class^="not-phone-portrait"]':
          {
            display: [
              'none',
              null,
              'inline-block',
              null,
              'inline-block',
              'inline-block',
              'inline-block'
            ]
          },
        '& > br[class^="phone-portrait"], & > span[class^="phone-portrait"]': {
          display: ['inline-block', null, 'none', null, 'none', 'none', 'none']
        },

        '& > span.phone-portrait': {
          '&__medium-indent': {
            ml: ['9rem', null, null, null, null, null]
          }
        },

        '& > span.not-phone-portrait': {
          '&__large-indent': {
            ml: [null, null, '14.9rem', null, '13.2rem', '16.9rem']
          },
          '&__small-indent': {
            ml: [null, null, '6rem', null, '5.1rem', '6.8rem']
          }
        }
      }}
    />
  );
};

const CaptionWithDecoration = ({ caption }) => {
  return (
    <Box
      className="__caption-with-decoration"
      sx={{
        gridColumn: ['1/13', null, '8/25', null, '9/ span 12', '14/ span 10'],
        mt: ['8rem', null, '7rem', null, '7.2rem', '13rem'],
        position: 'relative'
      }}>
      <Paragraph
        className="__description"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(caption.html) }}
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.35,
          fontSize: ['2.4rem', null, '3.2rem', null, '3.2rem', '3.2rem'],
          width: ['97%', null, 'auto', null, 'auto', 'auto']
        }}
      />
      <CircleSwirl
        sx={{
          width: ['8.5rem', null, '10.8rem', null, '11.8rem', '9.2rem'],
          top: ['-14.2rem', null, '3.4rem', null, '2.9rem', '4.2rem'],
          left: ['-3.8rem', null, '-18rem', null, '-21rem', '-16.2rem'],
          position: 'absolute'
        }}
      />
    </Box>
  );
};
