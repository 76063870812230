import React from 'react';

// External Components
import { Section, Box, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  AspectRatioImageContainer,
  PrimaryButton
} from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { floatingLaptop } from 'sections';

export const Strategy = ({
  data: {
    button_text,
    button_to,
    caption,
    description,
    headline,
    illustration_image
  }
}) => {
  return (
    <Section id="landing-page__strategy">
      <HeadlineAndCaption caption={caption} headline={headline} />
      <Illustration illustrationImage={illustration_image} />
      <DescriptionSpacer />
      <Description description={description} />
      <CtaButton buttonText={button_text} buttonTo={button_to} />
    </Section>
  );
};

const HeadlineAndCaption = ({ caption, headline }) => {
  return (
    <Box
      className="__headline-and-caption"
      sx={{
        gridColumn: [
          '1/ span 10',
          null,
          '2/ span 13',
          null,
          '3/ span 14',
          '5/ span 11'
        ],
        alignSelf: [null, null, null, null, null, 'end']
      }}>
      <Paragraph
        sx={{
          fontSize: ['1.4rem', null, '1.2rem', null, '1.2rem', '1.4rem'],
          fontFamily: 'body.normal',
          lineHeight: 1,
          letterSpacing: '0.1em',
          textTransform: 'uppercase'
        }}>
        {caption}
      </Paragraph>
      <Heading
        as="h2"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
        sx={{
          fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '4.8rem'],
          fontFamily: 'primary.bold',
          lineHeight: 1.35,
          mt: ['1.6rem', null, '1.5rem', null, '2.2rem', '1.5rem']
        }}
      />
    </Box>
  );
};

const Illustration = ({ illustrationImage: { image, alt } }) => {
  return (
    <Box
      className="__red-circle __illustration-image-container"
      sx={{
        mt: [null, null, '2.7rem', null, '0.9rem', 0],
        position: 'relative',
        paddingTop: '100%', // it makes the aspect ratio 1/1, we don't use aspect ratio properties because it's braking on IOS below version 15
        borderRadius: '50%',
        bg: 'orange300',
        gridColumn: [
          '6/13',
          null,
          '16/ span 7',
          null,
          '17/ span 6',
          '17/ span 5'
        ],
        alignSelf: 'start'
      }}>
      <AspectRatioImageContainer
        src={image}
        alt={alt}
        aspectRatios={1}
        variants={floatingLaptop}
        initial="initial"
        animate="animate"
        sx={{
          position: 'absolute',
          width: ['17rem', null, '18.4rem', null, '22.4rem', '22.4rem'],
          top: ['1.9rem', null, '1.9rem', null, '2.3rem', '3.6rem'],
          left: ['-1.4rem', null, '-1.8rem', null, '-1.9rem', '-1.1rem']
        }}
      />
    </Box>
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      className="__description"
      sx={{
        mt: ['5.1rem', null, '6.6rem', null, 0, 0],
        fontSize: ['1.6rem', null, '1.7rem', null, '1.8rem', '1.8rem'],
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        '& > span': {
          fontFamily: 'body.boldItalic'
        },
        gridColumn: [
          '1/ 13',
          null,
          '4/ span 19',
          null,
          '11/ span 12',
          '9/ span 11'
        ]
      }}
    />
  );
};

const CtaButton = ({ buttonText, buttonTo }) => {
  return (
    <PrimaryButton
      text={buttonText}
      to={buttonTo}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '4/ span 18',
          null,
          '3/ span 8',
          '2/ span 6'
        ],
        mt: ['3rem', null, '5rem', null, 0, 0],
        justifySelf: [null, null, 'start', null, 'start', 'start'],
        gridRow: [null, null, null, null, 3, 3],
        alignSelf: [null, null, null, null, 'center', 'center']
      }}
    />
  );
};

const DescriptionSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '5.9rem', '5.7rem']}
      sx={{
        display: ['none', null, 'none', null, 'block', 'block']
      }}
    />
  );
};
