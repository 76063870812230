import React from 'react';

// Local Components
import { Spacer } from 'components';

// Helper

// Self Components
import { animateSolutionBlocks, SolutionItemSection } from './components';

// Assets
import { ScratchLine } from 'components';

export const Solutions = ({ data: { solutions } }) => {
  React.useEffect(() => {
    const killSolutionBlockAnimation = animateSolutionBlocks();
    return killSolutionBlockAnimation;
  }, []);

  return (
    <>
      <SolutionItemSection
        id="landing-page__solution-one"
        data={solutions[0]}
      />
      <SolutionSpacer />
      <SolutionItemSection
        TextBlockDecoration={ScratchLineDecoration}
        isLeft
        id="landing-page__solution-two"
        data={solutions[1]}
      />
      <SolutionSpacer />
      <SolutionItemSection
        id="landing-page__solution-three"
        data={solutions[2]}
      />
    </>
  );
};

const SolutionSpacer = ({ sx }) => {
  return (
    <Spacer
      // height={['17.2rem', null, '14rem', null, '17.4rem', '18.3rem']}
      // adjusted solution spacer due to animation effect
      height={['7rem', null, '14rem', null, '17.4rem', '18.3rem']}
      sx={{
        ...sx
      }}
    />
  );
};

const ScratchLineDecoration = () => {
  return (
    <ScratchLine
      sx={{
        width: ['13.8rem', null, '13.8rem', null, '16rem', '14.5rem'],
        position: 'absolute',
        right: ['-4.3rem', null, 0, null, 0, '5.7rem'],
        top: ['1.2rem', null, '2rem', null, '2.8rem', '3.9rem'],
        zIndex: -1
      }}
    />
  );
};
