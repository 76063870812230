import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import {
  AspectRatioImageContainer,
  GridSpacer,
  HorizontalDivider
} from 'components';

// Assets
import { parallaxImage } from './animtation';

export const Header = ({
  data: {
    caption,
    focus_list,
    focus_list_title,
    headline,
    quick_info_title,
    quick_infos
  },
  staticData: { buildingInTrayImage, sittingManImage }
}) => {
  return (
    // Markup
    <ContentWrapper>
      <TopSpacer />
      <Caption caption={caption} />
      <Headline headline={headline} />
      <HeadlineImage
        buildingInTrayImage={buildingInTrayImage}
        sittingManImage={sittingManImage}
      />
      <FocusList title={focus_list_title} list={focus_list} />
      <QuickInfos title={quick_info_title} list={quick_infos} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section
    id="landing-page__header"
    as="header"
    sx={{
      overflow: 'hidden'
    }}>
    {children}
  </Section>
);

const Caption = ({ caption }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1,
        fontSize: ['4.8rem', null, '4.8rem', null, '4.8rem', '7.2rem'],
        gridColumn: [
          '1/13',
          null,
          '1/ span 11',
          null,
          '9/ span 8',
          '8/ span 8'
        ],
        position: 'relative',
        justifySelf: 'start',
        mt: ['10.8rem', null, '12.8rem', null, '7rem', '5.8rem'],
        gridRow: [null, null, null, null, 2]
      }}>
      {caption}
    </Paragraph>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h1"
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1,
        fontSize: ['8rem', null, '13.6rem', null, '8.4rem', '10.8rem'],
        gridColumn: ['1/13', null, '1/ span 17', null, '10/25', '9/25'],
        hyphens: 'auto',
        mt: ['2.4rem', null, '2.5rem', null, '1.6rem', '1.6rem'],
        gridRow: [null, null, 2, null, 3, 3]
      }}>
      {headline}
    </Heading>
  );
};

const HeadlineImage = ({ buildingInTrayImage, sittingManImage }) => {
  React.useEffect(() => {
    const killAnimation = parallaxImage();

    return killAnimation;
  }, []);
  return (
    <Box
      className="__headline-image"
      sx={{
        gridColumnEnd: [13, null, 24, null, 9, 8],
        justifySelf: 'end',
        alignSelf: 'start',
        mt: ['-12.2rem', null, '8.7rem', null, 0, 0],
        gridRow: [null, null, '2/ span 2', null, '2/ span 5', '2/ span 5'],
        position: 'relative',
        right: ['-4.7rem', null, '-0.1rem', null, '-2.6rem', '-3.3rem']
      }}>
      <AspectRatioImageContainer
        aspectRatios={1 / 1}
        src={buildingInTrayImage.src}
        alt={buildingInTrayImage.alt}
        sx={{
          width: ['26.9rem', null, '33rem', null, '34rem', '34rem']
        }}
        // animation value
        animate={{
          y: 10
        }}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1
        }}
      />
      <AspectRatioImageContainer
        aspectRatios={1 / 1.3}
        src={sittingManImage.src}
        alt={sittingManImage.alt}
        sx={{
          width: ['12.4rem', null, '15.9rem', null, '15.8rem', '15.8rem'],
          position: 'relative',
          left: ['7.4rem', null, '8.3rem', null, '8.8rem', '8.8rem'],
          mt: ['2rem', null, '2.4rem', null, '2.5rem', '2.5rem', '2.5rem']
        }}
      />
    </Box>
  );
};

const FocusList = ({ title, list }) => {
  return (
    <ListContainer
      title={title}
      list={list}
      sx={{
        gridColumnStart: [2, null, 2, null, 17, 17],
        mt: ['3.1rem', null, '17.5rem', null, '8.3rem', '5.5rem'],
        gridRow: [null, null, 3, null, 4, 4]
      }}
    />
  );
};
const QuickInfos = ({ title, list }) => {
  return (
    <ListContainer
      title={title}
      list={list}
      sx={{
        gridColumnStart: [2, null, 14, null, 12, 9],
        gridRow: [null, null, 4, null, 5, 4],
        mt: ['11.1rem', null, '5.1rem', null, '7.3rem', '22.3rem']
      }}
    />
  );
};

const ListContainer = ({ title, list, sx }) => {
  return (
    <Box
      className="__list-container"
      sx={{
        gridColumnEnd: [13, null, 'span 10', null, 'span 8', 'span 7'],
        width: ['28.2rem', null, '28.2rem', null, 'auto', '32rem'],
        position: 'relative',
        justifySelf: 'end',
        ...sx
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.boldItalic',
          color: 'red',
          textTransform: 'uppercase',
          position: 'absolute',
          writingMode: 'vertical-lr',
          transform: 'rotate(180deg)',
          top: 0,
          right: [
            'calc(100% + 2.3rem)',
            null,
            'calc(100% + 2.3rem)',
            null,
            'calc(100% + 2.6rem)',
            'calc(100% + 2.6rem)'
          ],
          lineHeight: 1,
          fontSize: ['1.4rem', null, '1.4rem', null, '1.6rem', '1.6rem'],
          letterSpacing: '0.05em'
        }}>
        {title}
      </Paragraph>
      {list.map(({ text }, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem text={text} />
            <HorizontalDivider
              sx={{
                mt: ['1.3rem', null, '1.3rem', null, '1.3rem', '1.3rem']
              }}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

const ListItem = ({ text }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.2,
        fontSize: ['1.5rem', null, '1.5rem', null, '1.6rem', '1.6rem'],
        '& ~ p': {
          mt: '2.4rem'
        }
      }}>
      {text}
    </Paragraph>
  );
};

// Spacer
const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '11.2rem', '14.8rem']}
      sx={{
        display: ['none', null, 'none', null, 'block', 'block']
      }}
    />
  );
};
