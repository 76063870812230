import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  useLandingPageData,
  Header,
  About,
  Solutions,
  Passion,
  Strategy,
  Suitability,
  SolutionHead
} from 'sections/landingPage';
import { Spacer } from 'components';

// Hooks
import { useKisLandingPageData } from 'graphqlHooks';

const Home = () => {
  const { seo, solutionHeadline, header } = useLandingPageData();

  const {
    header: cmsHeader,
    about: cmsAbout,
    solution: cmsSolution,
    passion: cmsPassion,
    strategy: cmsStrategy,
    suitability: cmsSuitability
  } = useKisLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={cmsHeader?.seo_image?.file} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={cmsHeader?.seo_image?.file} />
      </Helmet>
      <Header data={cmsHeader} staticData={header} />
      <Box
        as="main"
        sx={{
          overflow: 'hidden'
        }}>
        <Spacer
          height={['27.3rem', null, '22.7rem', null, '21.2rem', '43.9rem']}
        />
        <About data={cmsAbout} />
        <Spacer
          height={['18.1rem', null, '18.2rem', null, '28.3rem', '34.9rem']}
        />
        <SolutionHead staticData={solutionHeadline} data={cmsSolution} />
        <Spacer
          height={['11.9rem', null, '14.2rem', null, '20.8rem', '13.9rem']}
        />
        <Solutions data={cmsSolution} />
        <Passion data={cmsPassion} />
        <Strategy data={cmsStrategy} />
        <Spacer
          height={['20.8rem', null, '32.6rem', null, '31.3rem', '38.7rem']}
        />
        <Suitability data={cmsSuitability} />
        <Spacer height={['18rem', null, '21rem', null, '21rem', '30rem']} />
      </Box>
    </>
  );
};

export default injectIntl(Home);
