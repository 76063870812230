import React, { useEffect, Suspense } from 'react';

// External Components
import { Section, Box, Paragraph } from '@thepuzzlers/pieces';

// Assets
import { GridSpacer, PassionText, PrimaryButton } from 'components';
import { parallaxSunAnimation } from './animation';
const BlueBirdRive = React.lazy(() => import('./BlueBirdRive'));

export const Passion = ({
  data: { button_text, button_to, caption, headline }
}) => {
  return (
    <Section
      id="landing-page__passion"
      sx={{
        overflow: 'hidden'
      }}>
      <TopSpacer />
      <Box
        sx={{
          position: 'relative',
          gridColumn: ['1/13', null, '1/25', null, '1/25', '1/25'],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircleBackground />
        <Headline headline={headline} />
        <Caption caption={caption} />
        <Button text={button_text} to={button_to} />
      </Box>
      <BottomSpacer />
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <Box
      as="h2"
      sx={{
        position: 'relative'
      }}>
      <PassionText
        sx={{
          width: ['41.0rem', null, '74.5rem', null, '91.9rem', '111.8rem']
        }}
      />
      <BlueBirdDecoration />
    </Box>
  );
};

const BlueBirdDecoration = () => {
  return (
    <Box
      className="__blue-bird-decoration"
      sx={{
        position: 'absolute',
        width: ['8rem', null, '14.8rem', null, '15rem', '15rem'],
        height: ['8rem', null, '14.8rem', null, '15rem', '15rem'],
        top: ['-7.6rem', null, '-13.9rem', null, '-14.2rem', '-14.1rem'],
        left: ['21.4rem', null, '39.2rem', null, '49.4rem', '62rem']
      }}>
      <Suspense fallback={<Box />}>
        <BlueBirdRive />
      </Suspense>
    </Box>
  );
};

const Caption = ({ caption }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        fontSize: ['3.2rem', null, '5.6rem', null, '6.4rem', '7.2rem'],
        textAlign: 'center',
        mt: ['2.6rem', null, '5.8rem', null, '4.45rem', '5.7rem'],
        zIndex: 1,
        width: [null, null, null, null, null, '102.7rem']
      }}>
      {caption}
    </Paragraph>
  );
};

const Button = ({ text, to }) => {
  return (
    <PrimaryButton
      text={text}
      to={to}
      variant="buttons.primaryBig"
      sx={{
        mt: ['5rem', null, '6rem', null, '6rem', '4.5rem']
      }}
    />
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={['28.4rem', null, '49.3rem', null, '55.3rem', '59.4rem']}
    />
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer height={['18rem', null, '31rem', null, '31rem', '43.4rem']} />
  );
};

const CircleBackground = () => {
  React.useEffect(() => {
    const killAnimation = parallaxSunAnimation();

    return killAnimation;
  }, []);

  return (
    <Box
      className="__circle-background __parallax-sun"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '3/ span 20',
          null,
          '4/ span 18',
          '5/ span 16'
        ],
        backgroundImage: ({ colors }) =>
          `linear-gradient(to bottom, ${colors.orange} -17%, ${colors.background} 64.42%)`,
        borderRadius: '50%',
        position: 'absolute',
        // top: ['-10.9rem', null, '-19.3rem', null, '-23.6rem', '-19.5rem'], //original
        top: ['-13.9rem', null, '-21.3rem', null, '-25.6rem', '-21.5rem'], //adjusted for animation purpose
        width: ['31.8rem', null, '59.8rem', null, '74.8rem', '84.8rem'],
        height: ['31.8rem', null, '59.8rem', null, '74.8rem', '84.8rem']
      }}
    />
  );
};
