import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { ColumnedParagraph, GridSpacer, HorizontalDivider } from 'components';

export const Suitability = ({ data: { caption, headline, list } }) => {
  return (
    <Section id="landing-page__suitability">
      <HeadlineAndCaption caption={caption} headline={headline} />
      <HeadlineSpacer />
      {list.map((data, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem data={data} />
            <ListDivider />
          </React.Fragment>
        );
      })}
    </Section>
  );
};

const HeadlineAndCaption = ({ caption, headline }) => {
  return (
    <Box
      className="__headline-and-caption"
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '5/ span 16', '6/ span 14'],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          textTransform: 'uppercase',
          lineHeight: 1,
          fontSize: ['1.4rem', null, '1.8rem', null, '1.8rem', '1.8rem'],
          letterSpacing: '0.05em',
          textAlign: 'center'
        }}>
        {caption}
      </Paragraph>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.1,
          fontSize: ['4rem', null, '7.2rem', null, '6.4rem', '7.2rem'],
          textAlign: 'center',
          mt: ['1.6rem', null, '2rem', null, '2rem', '2.4rem'],
          width: [null, null, null, null, null, '71rem']
        }}>
        {headline}
      </Heading>
    </Box>
  );
};

const ListItem = ({ data: { title, first_paragraph, second_paragraph } }) => {
  return (
    <Box
      className="__list-item"
      sx={{
        gridColumn: ['1/13', null, '4/ span 18', null, '1/25', '1/25'],
        display: [null, null, null, null, 'grid', 'grid'],
        gridTemplateColumns: [
          null,
          null,
          null,
          null,
          '41.2rem 1fr',
          '52.2rem 1fr'
        ],
        columnGap: [null, null, null, null, '13.4rem', '7rem']
      }}>
      <Heading
        as="h3"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1,
          fontSize: ['2.8rem', null, '4rem', null, '4.8rem', '5.6rem']
        }}>
        {title}
      </Heading>

      <ColumnedParagraph
        firstText={first_paragraph}
        secondText={second_paragraph}
        isSecondStyle
        sx={{
          mt: ['2.4rem', null, '2.4rem', null, 0, 0]
        }}
      />
    </Box>
  );
};

const HeadlineSpacer = () => {
  return (
    <GridSpacer
      className="__headline-spacer"
      height={['8rem', null, '11rem', null, '11rem', '11.6rem']}
    />
  );
};

const ListDivider = () => {
  return (
    <HorizontalDivider
      className="__list-divider"
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '1/25', '1/25'],
        my: ['4rem', null, '8rem', null, '5rem', '6rem'],
        ':last-of-type': {
          mb: 0
        }
      }}
    />
  );
};
