import React, { Suspense } from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { Building, GridSpacer } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import StrikeLine from 'assets/svg/strikeline.svg';
const WhiteBirdRive = React.lazy(() => import('./WhiteBirdRive'));

// Assets

export const About = ({
  data: { headline, description, caption, second_description }
}) => {
  return (
    <Section id="landing-page__about">
      <DecoratedHeadline headline={headline} />
      <DecoratedCaption caption={caption} />
      <DescriptionsSpacer />
      <FirstDescription description={description} />
      <SecondDescription description={second_description} />
    </Section>
  );
};

const DecoratedHeadline = ({ headline }) => {
  return (
    <Box
      className="__decorated-headline"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 19',
          null,
          '3/ span 14',
          '4/ span 11'
        ],
        position: 'relative',
        justifySelf: 'start'
      }}>
      <Heading
        as="h2"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
        sx={{
          lineHeight: 1.15,
          fontFamily: 'primary.normal',
          fontSize: ['4.8rem', null, '5.6rem', null, '6.4rem', '6.4rem'],
          '& > span': {
            fontFamily: 'primary.bold'
          },
          hyphens: ['auto', null, 'unset', null, 'unset', 'unset'],
          textIndent: [null, null, '6rem', null, '7rem', '7rem'],
          // fix the safari hyphens issue
          width: ['31.9rem', null, 'auto', null, 'auto', 'auto']
        }}
      />
      <WhiteBirdDecoration />
    </Box>
  );
};

const WhiteBirdDecoration = () => (
  <Box
    sx={{
      position: 'absolute',
      width: '9rem',
      height: '9rem',
      bottom: ['93%', null, '91%', null, '87%', '91%'],
      right: ['5.5rem', null, '10.6rem', null, '13.5rem', '12.1rem']
    }}>
    <Suspense fallback={<Box />}>
      <WhiteBirdRive />
    </Suspense>
  </Box>
);
const DecoratedCaption = ({ caption }) => {
  // Put the custom strike line to the paragraph element

  React.useEffect(() => {
    const strikeThroughElement = document.createElement('img');
    document
      .querySelector('.__paragraph-with-strike-through em')
      .appendChild(strikeThroughElement);
    strikeThroughElement.src = StrikeLine;
    strikeThroughElement.alt = '';
  }, []);

  return (
    <Box
      className="__caption-with-decoration"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '4/ span 18',
          null,
          '8/ span 14',
          '9/ span 12'
        ],
        mt: ['4.6rem', null, '3.5rem', null, '5.6rem', '6.6rem'],
        position: 'relative'
      }}>
      <Paragraph
        className="__paragraph-with-strike-through"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(caption.html) }}
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.5,
          fontSize: ['2.8rem', null, '2.8rem', null, '3.2rem', '3.2rem'],

          '& > span': {
            fontFamily: 'primary.bold',
            '& > em': {
              // textDecoration: 'line-through',
              textDecorationColor: 'red',
              textDecorationThickness: '3px',
              position: 'relative',
              '& img': {
                position: 'absolute',
                top: '42%',
                left: 0,
                width: '100%'
              }
            }
          }
        }}
      />
      <Building
        sx={{
          position: 'absolute',
          top: [
            'calc(100% + 3rem)',
            null,
            '-11.7rem',
            null,
            '-15rem',
            '-15rem'
          ],
          right: [0, null, '-2.7rem', null, 0, '6.9rem'],
          width: '8.1rem'
        }}
      />
    </Box>
  );
};

const FirstDescription = ({ description }) => {
  return (
    <Description
      description={description}
      sx={{
        gridColumnStart: [1, null, 4, null, 3, 4],
        mt: ['15.6rem', null, '5.3rem', null, 0, 0]
      }}
    />
  );
};
const SecondDescription = ({ description }) => {
  return (
    <Description
      description={description}
      sx={{
        gridColumnStart: [1, null, 4, null, 14, 13],
        mt: ['6.4rem', null, '6.4rem', null, 0, 0]
      }}
    />
  );
};

const Description = ({ description, sx }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        fontSize: ['1.7rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        '& > span': {
          fontFamily: 'body.boldItalic'
        },
        gridColumnEnd: [13, null, 'span 17', null, 'span 9', 'span 8'],
        ...sx
      }}
    />
  );
};

const DescriptionsSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '7.7rem', '8.9rem']}
      sx={{
        display: ['none', null, 'none', null, 'block', 'block']
      }}
    />
  );
};
