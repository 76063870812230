import { gsap } from 'gsap';

export const parallaxSunAnimation = () => {
  const sunElement = document.querySelector('.__parallax-sun');
  const sectionElement = document.querySelector('#landing-page__passion');

  const animation = gsap.from(sunElement, {
    y: 100,
    scrollTrigger: {
      trigger: sectionElement,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 0.1
    }
  });
  return () => {
    animation.kill();
    animation?.scrollTrigger?.kill();
  };
};
