import { gsap } from 'gsap';

export const parallaxImage = () => {
  const headlineElement = document.querySelector(
    '#landing-page__header .__headline-image'
  );

  gsap.to(headlineElement, {
    y: -100,
    scrollTrigger: {
      trigger: '#landing-page__header',
      start: 'top top',
      end: 'bottom top',
      scrub: 0.1
    }
  });
};
