import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const animateSolutionBlocks = () => {
  // ANIMATION ELEMENTS

  const solutionContainers = gsap.utils.toArray(
    document.querySelectorAll('.__solution-item-container')
  );

  const solutionImages = gsap.utils.toArray(
    document.querySelectorAll('.__solution-image')
  );
  const solutionTitles = gsap.utils.toArray(
    document.querySelectorAll('.__solution-title')
  );
  const solutionDescriptions = gsap.utils.toArray(
    document.querySelectorAll('.__solution-description')
  );

  const solutionButtons = gsap.utils.toArray(
    document.querySelectorAll('.__solution-button')
  );

  // ANIMATION FUNCTION
  const createImageParallaxAnimation = ({
    element,
    trigger,
    endTrigger,
    startY,
    endY
  }) => {
    gsap.set(element, {
      y: startY
    });
    return gsap.to(element, {
      y: endY,
      scrollTrigger: {
        trigger: trigger,
        endTrigger,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0.1
      }
    });
  };

  const createTitleAndDescriptionParallaxAnimation = ({
    titleElement,
    descriptionElement,
    trigger,
    endTrigger,
    startY,
    endY
  }) => {
    gsap.set([titleElement, descriptionElement], {
      y: startY
    });
    return gsap.to([titleElement, descriptionElement], {
      y: endY,
      scrollTrigger: {
        trigger,
        endTrigger,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0.1
      }
    });
  };
  const createButtonParallaxAnimation = ({
    element,
    trigger,
    endTrigger,
    startY,
    endY
  }) => {
    gsap.set(element, {
      y: startY
    });
    return gsap.to(element, {
      y: endY,
      scrollTrigger: {
        trigger,
        endTrigger,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0.1
      }
    });
  };

  // This defined outside because we wan to kill it latter (the match media )
  let solutionImageAnimations = [];
  let titleAndDescriptionAnimation = [];
  let solutionButtonAnimation = [];

  const createParallaxAnimations = ({
    imagesStartY,
    imagesEndY,
    titleAndDescriptionStartY,
    titleAndDescriptionEndY,
    buttonStartY,
    buttonEndY
  }) => {
    ScrollTrigger.saveStyles([
      ...solutionImages,
      ...solutionTitles,
      ...solutionButtons,
      ...solutionContainers
    ]);

    solutionImageAnimations = solutionImages.map((element, index) =>
      createImageParallaxAnimation({
        element,
        trigger: solutionContainers[index],
        endTrigger: solutionContainers[index],
        startY: imagesStartY,
        endY: imagesEndY
      })
    );
    titleAndDescriptionAnimation = solutionTitles.map((titleElement, index) =>
      createTitleAndDescriptionParallaxAnimation({
        titleElement,
        descriptionElement: solutionDescriptions[index],
        trigger: solutionContainers[index],
        endTrigger: solutionContainers[index],
        startY: titleAndDescriptionStartY,
        endY: titleAndDescriptionEndY
      })
    );
    solutionButtonAnimation = solutionButtons.map((element, index) =>
      createButtonParallaxAnimation({
        element,
        trigger: solutionContainers[index],
        endTrigger: solutionContainers[index],
        startY: buttonStartY,
        endY: buttonEndY
      })
    );

    return () => {
      solutionImageAnimations.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
      titleAndDescriptionAnimation.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
      solutionButtonAnimation.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
    };
  };

  // Match Media
  ScrollTrigger.matchMedia({
    '(min-width: 1400px) ': () =>
      createParallaxAnimations({
        imagesStartY: 50,
        imagesEndY: -80,
        titleAndDescriptionStartY: 90,
        titleAndDescriptionEndY: -160,
        buttonStartY: 185,
        buttonEndY: -200
      }),
    '(min-width: 1000px) and (max-width: 1399px)': () =>
      createParallaxAnimations({
        imagesStartY: 40,
        imagesEndY: -80,
        titleAndDescriptionStartY: 80,
        titleAndDescriptionEndY: -140,
        buttonStartY: 175,
        buttonEndY: -150
      }),
    '(min-width: 750px) and (max-width: 999px)': () =>
      createParallaxAnimations({
        imagesStartY: 40,
        imagesEndY: -80,
        titleAndDescriptionStartY: 80,
        titleAndDescriptionEndY: -140,
        buttonStartY: 175,
        buttonEndY: -150
      }),
    '(max-width: 749px)': () =>
      createParallaxAnimations({
        imagesStartY: 40,
        imagesEndY: -80,
        titleAndDescriptionStartY: 80,
        titleAndDescriptionEndY: -140,
        buttonStartY: 175,
        buttonEndY: -150
      })
  });

  // KILL Function
  return () => {
    if (solutionImageAnimations.length > 0) {
      solutionImageAnimations.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
    }

    if (titleAndDescriptionAnimation.length > 0) {
      titleAndDescriptionAnimation.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
    }

    if (solutionButtonAnimation.length > 0) {
      solutionButtonAnimation.forEach((animation) => {
        animation.kill();
        animation?.scrollTrigger?.kill();
      });
    }
  };
};
