import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  AspectRatioImageContainer,
  GridSpacer,
  PrimaryButton
} from 'components';

// Helper
import { convertApiHtmlText, convertPtoSpan } from 'graphqlHooks/helper';

export const SolutionItemSection = ({
  id,
  data: { button_text, description, image, title, button_to },
  isLeft,
  TextBlockDecoration = null,
  ...props
}) => {
  return (
    <Section
      id={id}
      {...props}
      // Animation Value
      className="__solution-item-container">
      <Image isLeft={isLeft} image={image} />
      <TextBlock
        isLeft={isLeft}
        title={title}
        description={description}
        TextBlockDecoration={TextBlockDecoration}
      />
      <CtaButton
        isLeft={isLeft}
        buttonText={button_text}
        buttonTo={button_to}
      />
      <BottomSpacer />
    </Section>
  );
};

// Reusable Components

const Image = ({ image: { image, alt }, sx, isLeft }) => {
  return (
    <AspectRatioImageContainer
      className="__solution-image"
      src={image}
      alt={alt}
      aspectRatios={1 / 1.41}
      sx={{
        width: ['100%', null, '37.8rem', null, '41.2rem', '47rem'],
        alignSelf: 'start',
        gridColumn: isLeft
          ? ['1/13', null, '1/ span 11', null, '2/ span 10', '2/ span 9']
          : ['1/13', null, '13/25', null, '13/ span 10', '14/ span 9'],
        justifySelf: isLeft
          ? [null, null, 'end', null, 'unset', 'unset']
          : [null, null, 'start', null, 'unset', 'unset'],
        gridRow: [null, null, '1/ span 3', null, '1/ span 3', '1/span 3'],
        ...sx
      }}
    />
  );
};

const TextBlock = ({ title, description, sx, isLeft, TextBlockDecoration }) => {
  return (
    <Box
      sx={{
        zIndex: 1,
        gridColumn: isLeft
          ? ['1/13', null, '12/25', null, '13/ span 11', '13/ span 11']
          : ['1/13', null, '1/ span 13', null, '3/ span 11', '3/ span 11'],
        mt: isLeft
          ? ['2.4rem', null, '11.9rem', null, '25.7rem', '8.6rem']
          : ['3rem', null, '17.7rem', null, '32.5rem', '27.4rem'],
        gridRow: [null, null, 1, null, '1/ span 2', 1],
        position: 'relative',
        ...sx
      }}>
      <Heading
        className="__solution-title"
        as="h3"
        dangerouslySetInnerHTML={{ __html: convertPtoSpan(title.html) }}
        sx={{
          fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '5.6rem'],
          fontFamily: 'primary.normal',
          lineHeight: 1.35,
          '& strong': {
            fontFamily: 'primary.bold'
          },
          '& > span': {
            display: 'block',
            '&:nth-of-type(2)': {
              textIndent: ['6rem', null, '7rem', null, '8rem', '10.2rem']
            }
          },
          width: [null, null, '39rem', null, '45.7rem', 'auto']
        }}
      />
      <Paragraph
        className="__solution-description"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(description.html)
        }}
        sx={{
          fontSize: ['1.6rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          mt: ['2.4rem', null, '5rem', null, '5rem', '2.4rem'],
          '& > span': {
            fontFamily: 'body.boldItalic'
          },
          width: [null, null, null, null, '42rem', '41.6rem']
        }}
      />
      {TextBlockDecoration && <TextBlockDecoration />}
    </Box>
  );
};

const CtaButton = ({ buttonText, buttonTo, isLeft, sx }) => {
  return (
    <PrimaryButton
      className="__solution-button"
      text={buttonText}
      to={buttonTo}
      sx={{
        mt: ['3rem', null, '5rem', null, 0, '5.6rem'],
        gridColumn: isLeft
          ? ['1/13', null, '12/ span 10', null, '3/ span 7', '17/ span 5']
          : ['1/13', null, '1/ span 10', null, '16/ span 6', '9/ span 5'],
        justifySelf: isLeft
          ? [null, null, null, null, 'center', 'end']
          : [null, null, null, null, 'center', 'start'],
        gridRow: [null, null, null, null, 2, 2],
        // zIndex fix the button not clickable issue, when parallax animation active
        zIndex: 1,
        ...sx
      }}
    />
  );
};

// Bottom Spacer to avoid button cut-off due to section overflow hidden
const BottomSpacer = () => {
  return <GridSpacer height="1rem" />;
};
